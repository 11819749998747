<template>
  <v-card>
    <v-card-title>{{ fullpath }}</v-card-title>
    <v-card-subtitle>Nueva carpeta</v-card-subtitle>
    <v-card-text>
      <v-text-field
        ref="input"
        @keydown.enter="resolve({ fullpath })"
        @keydown.esc="reject"
        filled
        dense
        hide-details="auto"
        :rules="[rules.req]"
        v-model="name"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click.stop="reject" color="error">
        <v-icon left>mdi-close</v-icon>
        Cancelar
      </v-btn>
      <v-btn @click.stop="resolve({ fullpath })" color="primary">
        <v-icon left>mdi-folder-plus</v-icon>
        Crear
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";
import { useFocus, watchOnce } from "@vueuse/core";
import { ref, onMounted, computed } from "vue";

export default {
  props: {
    resolve: Function,
    reject: Function,
    path : String,
  },
  setup(props) {
    const input = ref(null);
    const name = ref('')

    onMounted(() => {
      const { focused } = useFocus(input, { initialValue: true });
      // watchOnce( focused, (v) => props.reject() )
    });

    const rules = ref({ req });

    const fullpath = computed(() => props.path + '/' + name.value)

    return { rules, input, fullpath, name, ...props };
  },
};
</script>
